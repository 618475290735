import { ProductLabelColor } from '~/types/common';
import { ProductSearchSortOrder, ProductSearchSortTarget } from '~/enums';

/** マスタの取得上限数 */
export const MASTER_LIMIT_ROWS = 500;

/** サーチの取得上限数 */
export const SEARCH_LIMIT_ROWS = 500;

/** 商品一覧1ページネーションごとの表示数 */
export const PRODUCT_LIST_PER_PAGE_LARGE = 40;
export const PRODUCT_LIST_PER_PAGE = 20;

/** 絞り込み項目のもっと見るボタンの表示件数 */
export const OPTIONS_LIMIT_PER_CLICK = 5;

/** 絞り込み項目の初期表示件数 */
export const OPTIONS_INITIAL_DISPLAY_NUM = 5;

/** 商品詳細の「同じ生産者の別アイテム」の最大表示件数 */
export const SAME_PRODUCER_ITEMS_LIMIT = 30;

/** レコメンドAPIのクエリ設定 */
export const RECOMMEND_API_SETTING = {
  FRAME_ID: 2,
  RF: 'a7d',
  LIMIT: 6,
  LF: 'suggest_view_name',
  LF_SALE: 'sale_info',
};

/** サーチAPIのファセット設定 */
export const SEARCH_API_FACET_SETTING = {
  FACET: 'on',
  MINCOUNT: 1,
  ROWS: 0,
};

/** 商品検索のクエリパラメータ名 */
export const PRODUCT_PARAM_QUERIES = {
  CATEGORY: '_ctg',
  COLOR: '_color',
  AREA: '_area',
  VARIETY: '_variety',
  TASTE_1: '_taste1',
  TASTE_2: '_taste2',
  SWEETNESS: '_sweetness',
  SIZE: '_size',
  SET: '_set',
  VINTAGE: '_vintage',
  // 旧システムクエリ
  VINTAGE1: '_vintage1',
  // 旧システムクエリ
  VINTAGE2: '_vintage2',
  CLASS: '_class',
  PRODUCER: '_producers',
  PRICE_1: '_price1',
  PRICE_2: '_price2',
  REVIEW_POINT: '_reviewpoint',
  WORD: '_word',
  SENSE: '_sense',
  NEW: '_new',
  FREE_SHIPPING: '_free_shipping',
  DISCOUNT: '_discount',
  QTY: '_qty',
  SORT_VINTAGE: '_sv',
  SORT_SIZE: '_ss',
  SORT_PRICE: '_sp',
  SORT_ARRIVAL: '_st',
  SORT_REVIEW: '_sr',
  SORT_MEDIA: '_sm',
  VINTAGE_INFO: '_vintage_info',
  CODE: '_code',
  AOC: '_aoc',
  TAG: '_tag',
  STYLE: '_style',
  MEDIA: '_media',
};

/** サーチAPIのパラメータ名 */
export const PRODUCT_SEARCH_PARAM = {
  CATEGORY: 'product_category_sm',
  COLOR: 'color_id_i',
  AREA: 'producing_area_sm',
  VARIETY: 'brand_sm',
  TASTE: 'taste_gauge_i',
  SWEETNESS: 'sweetness_s',
  SIZE: 'size_amount_i',
  SET: 'lot_calculation_quantity_i',
  VINTAGE: 'vintage_code_list_sm',
  CLASS: 'class_id_i',
  PRODUCER: 'producer_id_i',
  PRODUCER_NAME_EN: 'producer_name_english_s',
  PRODUCT_VARIANT_PRICE: 'product_variant_price_im',
  REVIEW_POINT: 'sort_r_rating_r',
  SENSE: 'sense_type_s',
  NEW: 'new_arrival_flag_i',
  FREE_SHIPPING: 'shipping_fee_discount_flag_i',
  DISCOUNT: 'sale_discount_rate_i',
  STOCK: 'has_stock_flag_i',
  CODE: 'product_label_sm',
  AOC: 'native_area_id_i',
  TAG: 'product_tag2_name_sm',
  STYLE: 'style_id_i',
  MEDIA: 'high_score_vintage_media_sm',
};

/** ソート選択肢（通常、生産者、特集の商品一覧共通） */
export const PRODUCT_SEARCH_SORT_OPTIONS = [
  { label: 'おすすめ順', target: null, order: null },
  { label: '価格が安い順', target: ProductSearchSortTarget.PRICE, order: ProductSearchSortOrder.ASC },
  { label: '価格が高い順', target: ProductSearchSortTarget.PRICE, order: ProductSearchSortOrder.DESC },
  { label: '生産年が若い順', target: ProductSearchSortTarget.VINTAGE, order: ProductSearchSortOrder.DESC },
  { label: '生産年が古い順', target: ProductSearchSortTarget.VINTAGE, order: ProductSearchSortOrder.ASC },
  { label: 'クチコミ評価の高い順', target: ProductSearchSortTarget.REVIEW, order: ProductSearchSortOrder.DESC },
  { label: '評価誌評価の高い順', target: ProductSearchSortTarget.MEDIA, order: ProductSearchSortOrder.DESC },
  { label: 'サイズの小さい順', target: ProductSearchSortTarget.SIZE, order: ProductSearchSortOrder.ASC },
  { label: 'サイズの大きい順', target: ProductSearchSortTarget.SIZE, order: ProductSearchSortOrder.DESC },
  { label: '新着順', target: ProductSearchSortTarget.ARRIVAL, order: ProductSearchSortOrder.DESC },
];

export const PRIMEUR_SEARCH_SORT_OPTIONS = [
  { label: '総合金額が安い順', target: null, order: null },
  { label: '総合金額が高い順', target: ProductSearchSortTarget.PRICE, order: ProductSearchSortOrder.DESC },
  { label: '評価誌評価の高い順', target: ProductSearchSortTarget.MEDIA, order: ProductSearchSortOrder.DESC },
];

export const PRODUCT_WINE_BAR_COLORS: { [Color in ProductLabelColor]?: { from: string; to: string } } = {
  WineRed: { from: 'rgba(179, 33, 48, 0.5)', to: '#8f222d' },
  WineWhite: { from: 'rgba(173, 205, 125, 0.5)', to: '#adcd7d' },
  WineRose: { from: 'rgba(226, 130, 130, 0.5)', to: '#e28282' },
  WineSparkling: { from: 'rgba(240, 210, 136, 0.5)', to: '#f0d288' },
  WineOrange: { from: 'rgba(229, 145, 89, 0.5)', to: '#e59159' },
  WineSweet: { from: '#fdedc6', to: '#fbc43d' },
};

export const PRODUCT_CATEGORY_ID = {
  WINE: '1',
  WINE_GOODS: '2',
  WINE_GLASS: '3',
  WINE_OPENER: '4',
  WINE_STOPPER: '7',
  SNACK: '11',
  GIFT_SET: '13',
  SNACK_MEAT: '41',
  SNACK_FISH: '46',
  SNACK_CHEESE: '26',
  SNACK_NUTS: '21',
  WINE_SET: '12',
  WINE_CELLAR: '37',
  ALCOHOL_OTHER: '34',
  NON_ALCOHOL: '29',
  WATER: '25',
  JUICE: '52',
  FORTIFIED_WINE: '43',
  DISTILLED_ALCOHOL: '44',
  SWEETENED_ALCOHOL: '31',
  JAPANESE_ALCOHOL: '57',
  CIDER: '32',
};

export const PRODUCT_PARENT_CATEGORY_ID = {
  GIFT_SET: '13',
  WINE_SET: '12',
  SNACK: '11',
  WINE_GOODS: '2',
  WINE_CELLAR: '37',
  OTHER: '34',
  NON_ALCOHOL: '29',
  WINE: '1',
};

// labelは本番マスタのカテゴリ名に合わせる
export const PRODUCT_PARENT_CATEGORY_LIST = [
  { id: PRODUCT_PARENT_CATEGORY_ID.GIFT_SET, label: 'ギフトセット' },
  { id: PRODUCT_PARENT_CATEGORY_ID.WINE_SET, label: 'ワインセット' },
  { id: PRODUCT_PARENT_CATEGORY_ID.SNACK, label: '食品' },
  { id: PRODUCT_PARENT_CATEGORY_ID.WINE_GOODS, label: 'ワイングッズ' },
  { id: PRODUCT_PARENT_CATEGORY_ID.WINE_CELLAR, label: 'ワインセラー' },
  { id: PRODUCT_PARENT_CATEGORY_ID.OTHER, label: 'その他のお酒' },
  { id: PRODUCT_PARENT_CATEGORY_ID.NON_ALCOHOL, label: 'その他飲料' },
  { id: PRODUCT_PARENT_CATEGORY_ID.WINE, label: 'ワイン' },
  { id: '58', label: 'レンタルセラーパック' }, // 本番
];

export const PRODUCT_COLOR_ID = {
  RED: '1',
  WHITE: '2',
  ROSE: '3',
  SPARKLING: '99',
  SPARKLING_WHITE: '5',
  SPARKLING_ROSE: '6',
  SPARKLING_RED: '7',
  ORANGE: '14',
  MIX: '8',
  SWEET: '12',
};

export const SWEET_OLD_IDS = ['15', '16', '17', '18', '19', '20'];

export const PRICE_RANGE_ITEMS = [
  { label: '1千円台', min: '1', max: '1999', id: 1 },
  { label: '2千円台', min: '2000', max: '2999', id: 2 },
  { label: '3千円台', min: '3000', max: '3999', id: 3 },
  { label: '4-5千円台', min: '4000', max: '5999', id: 4 },
  { label: '6-9千円台', min: '6000', max: '9999', id: 5 },
  { label: '1万円以上', min: '10000', max: null, id: 6 },
];

export const PRODUCT_CELLAR_INFO = {
  1: 'ラベル破れ',
  2: 'バックラベル不備',
  3: 'ネックシール不備',
  4: 'ビンキズ',
  5: 'キャップ不備',
  6: 'ロウキャップ',
  7: '巻紙破れ',
  8: 'BOX不備',
  9: '酒石酸(多)あり',
  10: 'セロファン無 (クリスタル)',
  11: '液面低下',
  12: 'その他',
};

export const SPARKLINGS = [
  PRODUCT_COLOR_ID.SPARKLING_WHITE,
  PRODUCT_COLOR_ID.SPARKLING_ROSE,
  PRODUCT_COLOR_ID.SPARKLING_RED,
];

export const WINE_COLOR_IDS = [
  PRODUCT_COLOR_ID.RED,
  PRODUCT_COLOR_ID.WHITE,
  PRODUCT_COLOR_ID.ROSE,
  PRODUCT_COLOR_ID.SPARKLING_WHITE,
  PRODUCT_COLOR_ID.SPARKLING_ROSE,
  PRODUCT_COLOR_ID.SPARKLING_RED,
  PRODUCT_COLOR_ID.ORANGE,
];

export const SWEETNESS_OPTIONS = {
  LITTLE_SWEET: { label: 'やや甘口', id: '1' },
  SWEET: { label: '甘口', id: '2' },
  VERY_SWEET: { label: '極甘口', id: '3' },
};

export const SWEETNESS_OPTION_IDS = [
  SWEETNESS_OPTIONS.LITTLE_SWEET.id,
  SWEETNESS_OPTIONS.SWEET.id,
  SWEETNESS_OPTIONS.VERY_SWEET.id,
];

export const SWEETNESS_OPTION_NAMES = [
  SWEETNESS_OPTIONS.LITTLE_SWEET.label,
  SWEETNESS_OPTIONS.SWEET.label,
  SWEETNESS_OPTIONS.VERY_SWEET.label,
];

export const PRODUCT_WINE_COLOR_LABELS = [
  { id: PRODUCT_COLOR_ID.RED, label: '赤ワイン' },
  { id: PRODUCT_COLOR_ID.WHITE, label: '白ワイン' },
  { id: PRODUCT_COLOR_ID.ROSE, label: 'ロゼワイン' },
  { id: PRODUCT_COLOR_ID.SPARKLING, label: 'スパークリングワイン' },
  { id: PRODUCT_COLOR_ID.ORANGE, label: 'オレンジワイン' },
];

export const PRODUCT_WINE_SET_COLOR_LABELS = [
  { id: PRODUCT_COLOR_ID.MIX, label: 'ミックス' },
  { id: PRODUCT_COLOR_ID.RED, label: '赤ワイン' },
  { id: PRODUCT_COLOR_ID.WHITE, label: '白ワイン' },
  { id: PRODUCT_COLOR_ID.SPARKLING, label: 'スパークリングワイン' },
  { id: PRODUCT_COLOR_ID.ROSE, label: 'ロゼワイン' },
];

export const PRODUCT_WINE_COLOR_LABELS_FOR_LINK = [
  { id: PRODUCT_COLOR_ID.RED, label: '赤ワイン' },
  { id: PRODUCT_COLOR_ID.WHITE, label: '白ワイン' },
  { id: PRODUCT_COLOR_ID.ROSE, label: 'ロゼワイン' },
  { id: ['5', '6', '7'], label: 'スパークリングワイン' },
  { id: PRODUCT_COLOR_ID.ORANGE, label: 'オレンジワイン' },
];

export const PRODUCT_ALL_COLOR_LABELS = [
  { id: PRODUCT_COLOR_ID.MIX, label: 'ミックス' },
  { id: PRODUCT_COLOR_ID.RED, label: '赤ワイン' },
  { id: PRODUCT_COLOR_ID.WHITE, label: '白ワイン' },
  { id: PRODUCT_COLOR_ID.ROSE, label: 'ロゼワイン' },
  { id: PRODUCT_COLOR_ID.SPARKLING, label: 'スパークリングワイン' },
  { id: PRODUCT_COLOR_ID.SPARKLING_RED, label: '赤スパークリングワイン' },
  { id: PRODUCT_COLOR_ID.SPARKLING_WHITE, label: '白スパークリングワイン' },
  { id: PRODUCT_COLOR_ID.SPARKLING_ROSE, label: 'ロゼスパークリングワイン' },
  { id: PRODUCT_COLOR_ID.ORANGE, label: 'オレンジワイン' },
];

export const PRODUCT_REVIEW_POINT_LABELS = {
  FIVE: { id: '5', label: '5 以上' },
  FOUR: { id: '4-5', label: '4 以上' },
  THREE: { id: '3-5', label: '3 以上' },
};

export const PRODUCT_SIZE_LABELS = {
  SMALL: { id: '375', label: '〜375ml' },
  MEDIUM: { id: '750', label: '750ml' },
  LARGE: { id: '1500', label: '1500ml〜' },
};

export const PRODUCT_SET_SIZE_LABELS = {
  SMALL: { id: '3', label: '3本以下' },
  MEDIUM: { id: '4-6', label: '4〜6本' },
  LARGE: { id: '7', label: '7本以上' },
};

export const PRODUCT_CELLAR_SIZE_LABELS = {
  SMALL: { id: '17-33', label: '17～33本収納' },
  MEDIUM: { id: '34-54', label: '34～54本収納' },
  LARGE: { id: '55-84', label: '55～84本収納' },
  MAX: { id: '85-169', label: '85～169本収納' },
};

export const PRODUCT_PRIMEUR_SIZE_LABELS = {
  ONE: { id: '750', label: '750ml' },
  THREE: { id: '2250', label: '750ml × 3本' },
  SIX: { id: '4500', label: '750ml × 6本' },
  TWELVE: { id: '9000', label: '750ml × 12本' },
  MAGNUM: { id: '1500', label: 'マグナムボトル' },
  DOUBLE_MAGNUM: { id: '3000', label: 'ダブルマグナムボトル' },
};

export const PRODUCT_SIZE_OPTIONS = {
  SMALL: 375,
  MEDIUM: 750,
  LARGE: 1500,
};

export const PRODUCT_SET_SIZE_OPTIONS = {
  SMALL: 3,
  MEDIUM: [4, 6],
  LARGE: 7,
};

export const PRODUCT_CELLAR_SIZE_OPTIONS = {
  SMALL: [17, 37],
  MEDIUM: [50, 77],
  LARGE: [92, 169],
};

export const PRODUCT_REVIEW_POINT_OPTIONS = {
  LOW: 3,
  MIDDLE: 4,
  HEIGH: 5,
};

export const GROUP_HT = [
  'bio_settings_ht',
  'product_name_ht',
  'product_name_english_ht',
  'producer_ht',
  'producer_name_english_ht',
  'area_1_ht',
  'area_2_ht',
  'area_3_ht',
  'color_group_ht',
  'sweetness_ht',
];

export const GROUP_HN = [
  'bio_settings_hn',
  'product_name_hn',
  'product_name_english_hn',
  'producer_hn',
  'producer_name_english_hn',
  'area_1_hn',
  'area_2_hn',
  'area_3_hn',
  'color_group_hn',
  'sweetness_hn',
];

export const GROUP_TAG = [
  'product_tag2_name_sm',
  'product_tag2_name_for_n_gram_nm',
  'product_tag2_name_for_analysis_tm',
];

export const PRODUCT_ORDER_NUMBER_THRESHOLD = '9223372036854775807';
export const PRODUCT_SALE_THRESHOLD = '10000000000000';

export const PRODUCT_LIST_MAP = {
  // 品種
  brand_id: 'brand_sm',
  // 色
  color_id: 'color_id_i',
  // 原産地呼称
  native_areas_id: 'native_area_id_i',
  // 生産者
  producer_id: 'producer_id_i',
  // 生産名
  product_name: 'product_name_s',
  // 商品コード
  product_code: 'product_code_s',
  // 商品ラベル
  product_labels: 'product_label_sm',
  // 生産地(絞り込み)
  producing_area: 'producing_area_sm',
  // 商品カテゴリ(ソート用)
  category: 'category_s',
  // 商品カテゴリ(絞り込み用)
  product_category_id: 'product_category_sm',
  // TODO: 要件が決まったら
  // サイズ
  size: 'size_amount_i',
  // 在庫数
  stock: 'has_stock_flag_i',
  // 商品の並び順１
  product_order_number_1: 'sort_order1_i',
  // 商品の並び順２
  product_order_number_2: 'sort_order2_i',
  // 商品の並び順３
  product_order_number_3: 'sort_order3_i',
  // 商品の並び順４
  product_order_number_4: 'sort_order4_i',
  // 定価
  product_price: 'lowest_cost_price_i',
  // 特価
  product_special_price: {
    asc: 'lowest_price_general_i asc',
    desc: 'highest_price_general_i asc',
  },
  // 販売開始
  product_sale_from: 'sales_start_d',
  // 販売終了
  product_sale_to: 'sales_end_d',
};

export const WINE_TYPE_MAP: { [key: string]: ProductLabelColor } = {
  スパークリング: 'WineSparkling',
  白スパークリング: 'WineSparkling',
  赤スパークリング: 'WineSparkling',
  ロゼスパークリング: 'WineSparkling',
  白ワイン: 'WineWhite',
  ビオディナミ: 'WineOrganic',
  赤ワイン: 'WineRed',
  ロゼワイン: 'WineRose',
  オレンジワイン: 'WineOrange',
  甘口ワイン: 'WineSweet',
};

export const WORD_TO_AVOID = '送料無料';

export const PRODUCTION_YEAR_THRESHOLD = 2100;

export const FIRST_SIZE = 750;

export const STOCK_NOTICE_BORDERLINE_COUNT = 6;

export const RATING_COUNT = 5;

export const CART_BUTTON_SECTION_ANCHOR_POINT = 'cart-button-section';

export const FLEXIBLE_SET_ITEM_SECTION_ANCHOR_POINT = 'flexible-set-item-section';

export const STOCK_SECTION_ANCHOR_POINT = 'stock-section';

export const PRODUCT_DETAIL_REVIEW_LIST_LIMIT = 4;

export const STAFF_REVIEW_ANCHOR_POINT = 'staffReview';

export const PRODUCT_RELATED_ARCHIVES_LIMIT = 4;

// tagの共通用のid
export const PRODUCT_TAG_ID = '0';

export const FOODS_IDS = ['41', '46', '26', '24', '23', '21', '20', '19', '17', '16', '15', '27', '11'];

export const OTHER_DRINKS_IDS = ['50', '52', '25', '55', '53', '47', '29'];

export const OTHER_ALCHOLE_DRINKS_IDS = ['44', '42', '33', '32', '31', '54', '43', '57', '34'];

export const WINE_GOODS_IDS = ['3', '4', '5', '6', '7', '8', '39', '40'];

export const FOODS_LIST = [
  { id: '41', label: '生ハム、サラミ' },
  { id: '46', label: '魚系おつまみ' },
  { id: '26', label: 'チーズ' },
  { id: '24', label: 'ソルト、スパイス、ビネガー' },
  { id: '23', label: 'クラッカー、スナック' },
  { id: '21', label: 'ドライフルーツ、ナッツ' },
  { id: '20', label: 'スイーツ' },
  { id: '19', label: 'オリーブ＆ピクルス' },
  { id: '17', label: 'ディップ、パテ' },
  { id: '16', label: 'オリーブオイル' },
  { id: '15', label: 'パスタ、リゾット' },
];

export const OTHER_DRINKS_LIST = [
  { id: '50', label: 'その他ノンアルコール飲料' },
  { id: '52', label: 'ジュース' },
  { id: '25', label: 'ミネラルウォーター' },
  { id: '55', label: 'ノンアルコールワイン、ワインテイスト飲料' },
  { id: '53', label: 'ワインテイスト飲料' },
  { id: '47', label: 'ノンアルコールワイン' },
];

export const OTHER_ALCOHOL_DRINKS_LIST = [
  { id: '43', label: '酒精強化ワイン（ポートワインなど）' },
  { id: '44', label: '蒸留酒（グラッパなど）' },
  { id: '42', label: 'ビール' },
  { id: '32', label: 'シードル' },
  { id: '31', label: '甘味果実酒' },
  { id: '54', label: 'フレーヴァードワイン' },
  { id: '57', label: '日本酒' },
  { id: '60', label: 'フルーツワイン' },
];

export const WINE_TYPE_LIST = [
  { id: '43', label: '酒精強化ワイン（ポートワインなど）' },
  { id: '44', label: '蒸留酒（グラッパなど）' },
  { id: '42', label: 'ビール' },
  { id: '32', label: 'シードル' },
  { id: '31', label: '甘味果実酒' },
  { id: '54', label: 'フレーヴァードワイン' },
  { id: '29', label: 'その他飲料' },
];

export const WINE_GOODS_ARCHIVES_LIST = [
  { id: 'DG', label: '赤ワイン用グラス' },
  { id: 'WT', label: '白ワイン用グラス' },
  { id: 'CPG', label: 'シャンパーニュ用グラス' },
  { id: 'A1', label: '１本用クーラー' },
  { id: 'Z9', label: '複数本用クーラー' },
  { id: 'JY', label: '洗浄・ケア用品' },
  { id: 'JZ', label: 'キッチン雑貨' },
  { id: 'J2', label: 'ワインバッグ' },
  { id: 'ZP', label: 'チーズボード' },
];

export const WINE_GOODS_ITEM_LIST = [
  { id: '3', label: 'ワイングラス一覧' },
  { id: '4', label: 'ソムリエナイフ、オープナー一覧' },
  { id: '7', label: 'ワインセーバー、ストッパー一覧' },
  { id: '5', label: 'ワインクーラー一覧' },
  { id: '6', label: 'デキャンタ一覧' },
  { id: '40', label: '書籍&ＣＤ' },
  { id: '8', label: 'その他グッズ一覧' },
  { id: '39', label: '食器、カトラリー一覧' },
  { id: '56', label: 'グラス付きワイン一覧' },
];

export const WINE_GOODS_LIST = [
  { id: '3', label: 'ワイングラス' },
  { id: '4', label: 'ソムリエナイフ、オープナー' },
  { id: '7', label: 'ワインセーバー、ストッパー' },
  { id: '5', label: 'ワインクーラー' },
  { id: '6', label: 'デキャンタ' },
  { id: '40', label: '書籍&ＣＤ' },
  { id: '8', label: 'その他グッズ' },
  { id: '39', label: '食器、カトラリー' },
  { id: '56', label: 'グラス付きワイン' }, // マスタ上親カテゴリはワイン、ラベルは「ワイン その他」、導線は関連リンクのみ
];

export const S3_BASE_KEYWORDS_DIR = '/s3/lp/data';
export const S3_BASE_AOC_DIR = '/s3/lp/data/aoc';
export const S3_BASE_VARIETY_DIR = '/s3/lp/data/brand';

export const PRODUCT_LIST_TYPE = {
  AOC: 'AOC',
  VARIETY: 'VARIETY',
  TAG: 'TAG',
  COLOR: 'COLOR',
  AREA: 'AREA',
};

export const CATCHCOPY = 'catchcopy';

export const MAX_LEAFLET_COUNT = 2;

export const PRODUCT_CART_BUTTON = {
  PRODUCT_LIST: '商品一覧_カートイン',
  PRODUCT_DETAIL_TOP: '商品詳細_カートイン_上部',
  PRODUCT_DETAIL_CENTER: '商品詳細_カートイン_中部',
  PRODUCT_DETAIL_BOTTOM: '商品詳細_カートイン_下部',
};

// 品種リスト
export const VARIETY_LIST = [
  { id: '2', label: 'カベルネ・ソーヴィニヨン' },
  { id: '1', label: 'メルロ' },
  { id: '3', label: 'ピノ・ノワール' },
  { id: '4', label: 'シャルドネ' },
  { id: '9', label: 'ソーヴィニヨン・ブラン' },
  { id: '13', label: 'リースリング' },
  { id: '26', label: '甲州' },
  { id: '6', label: 'シラー' },
  { id: '10', label: 'グルナッシュ' },
  { id: '21', label: 'ヴィオニエ' },
  { id: '32', label: 'ゲヴェルツトラミネール' },
  { id: '5', label: 'カベルネ・フラン' },
  { id: '57', label: 'シュナン・ブラン' },
  { id: '8', label: 'サンジョヴェーゼ' },
  { id: '11', label: 'ネッビオーロ' },
  { id: '25', label: 'モスカート' },
  { id: '47', label: 'マスカット・ベーリーＡ' },
  { id: '18', label: 'テンプラニーリョ' },
  { id: '43', label: 'ジンファンデル' },
  { id: '22', label: 'ピノ・グリ' },
  { id: '31', label: 'アリゴテ' },
  { id: '15', label: 'マルベック' },
  { id: '19', label: 'カルメネール' },
  { id: '12', label: 'セミヨン' },
  { id: '23', label: 'ガメイ' },
  { id: '29', label: 'バルベラ' },
  { id: '55', label: 'ガルガーネガ' },
  { id: '30', label: 'ヴェルメンティーノ' },
  { id: '62', label: 'モンテプルチアーノ' },
  { id: '27', label: 'ピノ・ブラン' },
];

// シーン
export const SCENE_LIST = [{ id: 'GY', label: 'ギフト' }];

// ワイン関連グッズ
export const GOODS_ARCHIVE_LIST = [
  { id: 'G2', label: 'ワイングラス' },
  { id: 'WD', label: 'ワインセラー' },
];
export const WINE_GOODS_LINK_LIST = [
  { id: '2', label: 'ワイングッズ' },
  { id: '11', label: 'グルメなおつまみ' },
  { id: '56', label: 'グラス付きワイン' },
];

// 関連リンク一式B
export const RELATED_LINK_B = [
  {
    id: '2',
    label: 'ワイングッズ',
  },
  { id: '11', label: '食品' },
  { id: '12', label: 'ワインセット' },
  { id: '13', label: 'ギフトセット' },
  { id: '56', label: 'グラス付きワイン' },
  { id: '29', label: 'その他飲料' },
  { id: '34', label: 'その他のお酒' },
];

// 口コミのハッシュ
export const REVIEW_HASH = '#detail_Rtoaster';

// TOPページのシーン、キーワードから探す
export const TOP_SCENE_LIST_PC = [
  '誕生日、記念日',
  '普段の夜ご飯と',
  '夏に飲みたい',
  'キャンプ＆バーベキューに',
  'ブライダルギフト',
  '昇進お祝い',
  'みんなでパーティー',
  'ワイン会に',
  'リラックスタイムに',
  'スポーツ観戦に',
];
export const TOP_SCENE_LIST_SP = ['誕生日、記念日', '普段の夜ご飯と', '夏に飲みたい', 'ブライダルギフト'];
export const TOP_KEYWORD_LIST_PC = [
  '樽香のある白',
  '飲みやすい赤',
  'ハーフボトル',
  'ミニサイズ・缶ワイン',
  '高評価',
  'ナチュラルワイン',
  '肉料理に',
  '動物ラベル',
  'アーティストが手掛けるラベル',
  'お気に入り登録の多いアイテム',
];
export const TOP_KEYWORD_LIST_SP = ['飲みやすい赤', 'ハーフボトル', 'ナチュラルワイン', '高評価'];

// スタイル紹介
export const STYLE_INFO_LIST = [
  {
    colorId: PRODUCT_COLOR_ID.RED,
    styles: [
      {
        type: 'チャーミング&パフュームド',
        bg_color_class: 'bg-redwine-charming',
        text_class: 'text-redwine-charming',
        border_class: 'border-redwine-charming',
        image: 'glass-red-a',
        info: '赤系果実の香りやフローラルなアロマが特徴。タンニンが少ないライトボディの飲みやすいスタイルで、熟成の必要がなく、今すぐ飲んで美味しい赤ワインです。',
        example: 'ピノ・ノワール（アルザス、ドイツ、ニューワールド）、ガメイ（ボージョレー）、マスカット・ベーリーA',
        style_id: 1,
      },
      {
        type: 'エレガント&クラシック',
        bg_color_class: 'bg-redwine-elegant',
        text_class: 'text-redwine-elegant',
        border_class: 'border-redwine-elegant',
        image: 'glass-red-b',
        info: '伝統産地から生み出されるエレガントで優美な味わいの赤ワイン。ワインによって持っている風味やタンニンの強さ、ボディは異なりますが、各産地のテロワールを反映しているのが特徴です。長期の熟成ポテンシャルに優れたワインも多く取り揃えています。',
        example:
          'ピノ・ノワール（ブルゴーニュ）、ネッビオーロ、サンジョヴェーゼ（キャンティ、ブルネッロ）、シラー（北ローヌ）、カベルネ・フラン',
        style_id: 2,
      },
      {
        type: 'リッチ&グラマー',
        bg_color_class: 'bg-redwine-rich',
        text_class: 'text-redwine-rich',
        border_class: 'border-redwine-rich',
        image: 'glass-red-c',
        info: '濃厚な果実味が特徴のミディアム～フルボディの赤ワイン。リッチで飲み応えたっぷりな赤ワインは熟成を待たずとも愉しめ、果実味たっぷりだからこそどんな方でも親しみやすい魅力があります。',
        example: 'シラーズ（オーストラリア）、マルベック、ジンファンデル、グルナッシュ（シャトーヌフ・デュ・パプ）',
        style_id: 4,
      },
      {
        type: 'パワフル&ストラクチャー',
        bg_color_class: 'bg-redwine-powerful',
        text_class: 'text-redwine-powerful',
        border_class: 'border-redwine-powerful',
        image: 'glass-red-d',
        info: '凝縮感のある果実味としっかりとしたタンニンが特徴の、力強いミディアム～フルボディの赤ワイン。熟成のポテンシャルに優れているワインも多く、熟成により複雑のある風味も楽しめます。',
        example:
          'カベルネ・ソーヴィニヨン（ボルドー、ナパ）、テンプラニーリョ（リオハ）、サンジョヴェーゼ（ブルネッロ、ボルゲリ）',
        style_id: 3,
      },
    ],
  },
  {
    colorId: PRODUCT_COLOR_ID.WHITE,
    styles: [
      {
        type: 'アロマティック＆ピュア',
        bg_color_class: 'bg-whitewine-aromatic',
        text_class: 'text-whitewine-aromatic',
        border_class: 'border-whitewine-aromatic',
        image: 'glass-white-a',
        info: '香り豊かで、みずみずしい味わいの白ワイン。ブドウ品種特有の香りが強く、それらが花やフルーツ、ハーブなどのアロマとしてワインに表れているのが特徴です。',
        example: 'リースリング、ゲヴュルツトラミネール、ソーヴィニヨン・ブラン、ピノ・グリ、甲州',
        style_id: 5,
      },
      {
        type: 'エレガント＆ミネラリー',
        bg_color_class: 'bg-whitewine-elegant',
        text_class: 'text-whitewine-elegant',
        border_class: 'border-whitewine-elegant',
        image: 'glass-white-b',
        info: 'フレッシュな柑橘系果実や桃などの有核果実の風味に、高い酸とミネラル感が重なった優美な味わいの白ワイン。樽を使ったものもありますが、綺麗に溶け込んでいるのが特徴です。',
        example: 'シャルドネ（ブルゴーニュ、シャブリ）、リースリング、アルバリーニョ',
        style_id: 7,
      },
      {
        type: 'フルーティー＆ライプ',
        bg_color_class: 'bg-whitewine-fruity',
        text_class: 'text-whitewine-fruity',
        border_class: 'border-whitewine-fruity',
        image: 'glass-white-c',
        info: 'ジューシーな果実味が主体の、フルーティーな味わいの白ワイン。有核果実やトロピカルフルーツのしっかりとした果実味と、酸のバランスに優れています。',
        example: 'ルーサンヌ＆マルサンヌ、ヴィオニエ、シャルドネ（ニューワールド）',
        style_id: 6,
      },
      {
        type: 'リッチ＆コンプレックス',
        bg_color_class: 'bg-whitewine-rich',
        text_class: 'text-whitewine-rich',
        border_class: 'border-whitewine-rich',
        image: 'glass-white-d',
        info: '有核果実やトロピカルフルーツを思わせる濃厚な果実味や、しっかりとした樽香を伴ったリッチな味わい、またはスパイスや蜂蜜のような甘さを伴った複雑な味わいの白ワイン。飲み応えのあるフルボディの仕上がりです。',
        example: 'シャルドネ（カリフォルニア）、貴腐ワイン',
        style_id: 8,
      },
    ],
  },
  {
    colorId: PRODUCT_COLOR_ID.ROSE,
    styles: [
      {
        type: 'フレッシュ＆ドライ',
        bg_color_class: 'bg-rosewine-fresh',
        text_class: 'text-rosewine-fresh',
        border_class: 'border-rosewine-fresh',
        image: 'glass-rose-a',
        info: 'プロヴァンスに代表される淡い色合いのロゼは現在ロゼ界のトレンドセッター。主に白ワインとほぼ同じ工程の「直接圧搾法」を用いて造られるため、色合いやタンニンが軽く抽出されるのが特徴で、繊細な風味は和食に代表される軽やかな食事にぴったりです。',
        example: 'プロヴァンス、ロワール',
        style_id: 9,
      },
      {
        type: 'リッチ＆フルーティー',
        bg_color_class: 'bg-rosewine-rich',
        text_class: 'text-rosewine-rich',
        border_class: 'border-rosewine-rich',
        image: 'glass-rose-b',
        info: '根強い人気を誇るしっかりとした色合いのロゼワイン。鮮やかな色合いのロゼは主に「マセラシオン」や「セニエ法」を用いて造られるため、しっかりと色合いやタンニンが抽出されているのが特徴です。より赤ワインに近い味わいで、しっかりとした肉料理とも好相性。合わせる食事の幅が広がります。',
        example: 'タヴェル、ボルドー',
        style_id: 10,
      },
    ],
  },
  {
    colorId: PRODUCT_COLOR_ID.ORANGE,
    styles: [
      {
        type: 'ライト＆ピュア',
        bg_color_class: 'bg-orangewine-light',
        text_class: 'text-orangewine-light',
        border_class: 'border-orangewine-light',
        image: 'glass-orange-a',
        info: '短期間のマセラシオンで造られるオレンジワインは、白ワインのような軽快さがありつつ、果皮由来の複雑性のある味わいに仕上がります。程良い複雑性を備えた軽やかでライトな味わいは、オレンジワイン入門にも最適です。',
        example: '甲州、グリューナー・ヴェルトリーナー',
        style_id: 14,
      },
      {
        type: 'アロマティック＆フルーティー',
        bg_color_class: 'bg-orangewine-aromatic',
        text_class: 'text-orangewine-aromatic',
        border_class: 'border-orangewine-aromatic',
        image: 'glass-orange-b',
        info: 'アロマティック品種から造られるオレンジワインは、ブドウの香り成分がマセラシオンによって最大限に引き出され、より芳香性豊かで華やかな味わいとなります。アロマティックな白ワインが好きな方に是非お試し頂きたいタイプです。',
        example: 'ゲヴェルツトラミネール、ミュスカ',
        style_id: 15,
      },
      {
        type: 'パワフル＆ストラクチャー',
        bg_color_class: 'bg-orange-powerful',
        text_class: 'text-orange-powerful',
        border_class: 'border-orange-powerful',
        image: 'glass-orange-c',
        info: '白ワインにはないしっかりとした渋みやストラクチャーを持ち合わせた力強い味わいのオレンジワイン。果皮が厚いブドウ品種を、オレンジワインのルーツであるジョージアの「クヴェヴリ」製法などで長期間マセラシオンすることで生み出されます。赤ワインと同じように少し高めの温度でお愉しみください。',
        example: 'ジョージア、リボッラ・ジャッラ（フリウリ）',
        style_id: 16,
      },
    ],
  },
  {
    colorId: PRODUCT_COLOR_ID.SPARKLING,
    styles: [
      {
        type: 'フレッシュ＆ドライ',
        bg_color_class: 'bg-sparklingwhite-fresh',
        text_class: 'text-sparklingwhite-fresh',
        border_class: 'border-sparklingwhite-fresh',
        image: 'glass-sparklingwhite-a',
        info: 'すっきり辛口でどんなシチュエーションでも大活躍するスパークリングワイン。しっかり冷やして、キリっとした爽やかな味わいをお楽しみください。',
        example: 'ニューワールド',
        style_id: 11,
      },
      {
        type: 'フルーティー＆バランス',
        bg_color_class: 'bg-sparklingwhite-fruity',
        text_class: 'text-sparklingwhite-fruity',
        border_class: 'border-sparklingwhite-fruity',
        image: 'glass-sparklingwhite-b',
        info: 'しっかりとした果実味と、酸のバランスに優れたスパークリングワイン。フレッシュでフルーティーな味わいで、製法により多様なアロマや風味が生み出されています。',
        example: 'プロセッコ、カヴァ、クレマン、ランブルスコ',
        style_id: 12,
      },
      {
        type: 'エレガント＆コンプレックス',
        bg_color_class: 'bg-sparklingwhite-elegant',
        text_class: 'text-sparklingwhite-elegant',
        border_class: 'border-sparklingwhite-elegant',
        image: 'glass-sparklingwhite-c',
        info: '瓶内二次発酵や長期の瓶内熟成に由来する、エレガントで複雑な味わいのスパークリングワイン。シャンパーニュを筆頭に、乾杯での一杯や特別な日の贈り物など、様々な場面を華やかに彩ります。',
        example: 'シャンパーニュ、フランチャコルタ',
        style_id: 13,
      },
    ],
  },
];

// スパークリングワインの色別情報(商品詳細画面で使用)
export const SPARKLING_ADDITIONAL_LIST = [
  {
    colorId: PRODUCT_COLOR_ID.SPARKLING_RED,
    styles: [
      {
        bg_color_class: 'bg-sparklingred-fresh',
        bg_bar_color_class: 'bg-sparklingred-fresh-bar',
        text_class: 'text-sparklingred-fresh',
        border_class: 'border-sparklingred-fresh',
        image: 'glass-sparklingred-a',
        style_id: 11,
      },
      {
        bg_color_class: 'bg-sparklingred-fruity',
        bg_bar_color_class: 'bg-sparklingred-fruity-bar',
        text_class: 'text-sparklingred-fruity',
        border_class: 'border-sparklingred-fruity',
        image: 'glass-sparklingred-b',
        style_id: 12,
      },
      {
        bg_color_class: 'bg-sparklingred-elegant',
        bg_bar_color_class: 'bg-sparklingred-elegant-bar',
        text_class: 'text-sparklingred-elegant',
        border_class: 'border-sparklingred-elegant',
        image: 'glass-sparklingred-c',
        style_id: 13,
      },
    ],
  },
  {
    colorId: PRODUCT_COLOR_ID.SPARKLING_WHITE,
    styles: [
      {
        bg_color_class: 'bg-sparklingwhite-fresh',
        bg_bar_color_class: 'bg-sparklingwhite-fresh-bar',
        text_class: 'text-sparklingwhite-fresh',
        border_class: 'border-sparklingwhite-fresh',
        image: 'glass-sparklingwhite-a',
        style_id: 11,
      },
      {
        bg_color_class: 'bg-sparklingwhite-fruity',
        bg_bar_color_class: 'bg-sparklingwhite-fruity-bar',
        text_class: 'text-sparklingwhite-fruity',
        border_class: 'border-sparklingwhite-fruity',
        image: 'glass-sparklingwhite-b',
        style_id: 12,
      },
      {
        bg_color_class: 'bg-sparklingwhite-elegant',
        bg_bar_color_class: 'bg-sparklingwhite-elegant-bar',
        text_class: 'text-sparklingwhite-elegant',
        border_class: 'border-sparklingwhite-elegant',
        image: 'glass-sparklingwhite-c',
        style_id: 13,
      },
    ],
  },
  {
    colorId: PRODUCT_COLOR_ID.SPARKLING_ROSE,
    styles: [
      {
        bg_color_class: 'bg-sparklingrose-fresh',
        bg_bar_color_class: 'bg-sparklingrose-fresh-bar',
        text_class: 'text-sparklingrose-fresh',
        border_class: 'border-sparklingrose-fresh',
        image: 'glass-sparklingrose-a',
        style_id: 11,
      },
      {
        bg_color_class: 'bg-sparklingrose-fruity',
        bg_bar_color_class: 'bg-sparklingrose-fruity-bar',
        text_class: 'text-sparklingrose-fruity',
        border_class: 'border-sparklingrose-fruity',
        image: 'glass-sparklingrose-b',
        style_id: 12,
      },
      {
        bg_color_class: 'bg-sparklingrose-elegant',
        bg_bar_color_class: 'bg-sparklingrose-elegant-bar',
        text_class: 'text-sparklingrose-elegant',
        border_class: 'border-sparklingrose-elegant',
        image: 'glass-sparklingrose-c',
        style_id: 13,
      },
    ],
  },
];

export const MEDIA_ORDERS = ['WA', 'WS', 'JS', 'V', 'WE', 'D'];

export const SALES_STATUS = {
  AVAILABLE_SALE: 'available_sale',
};
