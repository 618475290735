










import { defineComponent, onMounted, computed, useMeta, useRoute } from '@nuxtjs/composition-api';
import AppAlert from '~/components/partials/App/AppAlert.vue';
import { useKarteUserTag } from '~/composables/karte/useKarteUserTag';
import { useEnotecaApp } from '~/composables/useEnotecaApp';
import { useRuntimeConfig } from '~/composables/useRuntimeConfig';
import { useStore } from '~/composables/useStore';
import { url } from '~/utils';
import { getOptanonClass } from '~/utils/oneTrust';

export default defineComponent({
  components: {
    AppAlert,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const { isEnotecaAppWebView } = useEnotecaApp();
    const { RUNTIME_ENV } = useRuntimeConfig();

    const authenticated = computed(() => store.state.auth.authenticated);
    const { setKarteUserTag } = useKarteUserTag();

    useMeta(() => {
      const type = !isEnotecaAppWebView.value ? 'text/plain' : 'text/javascript';

      const isDefaultLoopasTrack = () => {
        if ([url('CART'), url('ORDER_COMPLETE')].includes(route.value.path)) return false;
        if (route.value.path.startsWith('/item/detail') || route.value.path.startsWith('/primeur/detail')) return false;
        return true;
      };

      return {
        script: [
          { type, src: '/js/facebook.js', class: getOptanonClass('targeting', 'analytics'), defer: true },
          {
            type,
            src: '//7220851.collect.igodigital.com/collect.js',
            class: getOptanonClass('targeting'),
            defer: true,
          },
          { type, src: '/js/collectCodeCookie.js', class: getOptanonClass('targeting'), defer: true },
          { type, src: '//static.criteo.net/js/ld/ld.js', class: getOptanonClass('targeting'), defer: true },
          { src: 'https://j.wovn.io/1', 'data-wovnio': 'key=fXmEAS', async: true },
          {
            type: 'text/javascript',
            src: RUNTIME_ENV === 'live' ? '/js/loopass-prod.js' : '/js/loopass-stg.js',
            class: getOptanonClass('targeting'),
            key: route.value.path,
          },
          {
            type: 'text/javascript',
            innerHTML: isDefaultLoopasTrack() ? 'loopassDmpTrack();' : '',
            key: route.value.path,
          },
        ],
      };
    });

    onMounted(async () => {
      await Promise.allSettled([
        store.dispatch('modules/cart/fetchCarts', {}),
        store.dispatch('internals/header/fetchHeaderMenuBadges', {}),
        setKarteUserTag(authenticated.value),
      ]);
    });

    return {};
  },
  head: {
    link: [
      { rel: 'icon', href: '/favicon.ico' },
      { rel: 'apple-touch-icon', href: '/apple-touch-icon.png' },
      { rel: 'preconnect', href: 'https://fonts.gstatic.com' },
      { rel: 'stylesheet', href: 'https://fonts.googleapis.com/css2?family=Roboto:wght@300;700&display=swap' },
    ],
  },
});
